@font-face {
    font-family: 'Poppins'; /* Nome que você deseja dar à fonte */
    src: url('../fonts/Poppins.woff2'); /* Caminho para o arquivo TTF */
  }

body {
    height: 100vh;
    padding: 0 !important;
    margin: 0 !important;

    background-image: url(../images/pattern.png);
    background-color: #101010 !important;


    font-family: 'Poppins', sans-serif !important;

    overscroll-behavior: contain;
}

#root {
    height: 100%;
}

hr {
    border-top: 2px solid rgba(255,255,255,1);
    opacity: 100%;
}

.form-label {
    margin-bottom: .25em;
    font-weight: bold;
}

.label-required::after {
    content: " *";
    color: red;
}

.nav-link {
    color: gray;
}

.nav-link:hover {
    color: white;
}